import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from '../app.component';
import { PageContentItem } from 'src/classes/content-classes';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css', '../app.component.css'],
})
export class NewsComponent implements OnInit {
  @Input() parent: AppComponent;
  pageContent: PageContentItem[];
  constructor() {}

  ngOnInit(): void {
    if (this.parent.canInit !== true) {
      setTimeout(() => {
        this.ngOnInit();
      }, 0);

      return;
    }
    this.pageContent = this.parent.resources.content.news;
  }
}
