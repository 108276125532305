<div class="car-wrap">
  <div>
    <mat-icon>arrow_left</mat-icon>
  </div>
  <div class="items-wrap">
    <div [ngClass]="prevItemClass()">
      <app-car-small-item
        [item]="this.prevItem"
        *ngIf="this.prevItem"
      ></app-car-small-item>
    </div>
    <div [ngClass]="curItemClass()">
      <app-car-small-item
        [item]="this.curItem"
        *ngIf="this.curItem"
      ></app-car-small-item>
    </div>
    <div [ngClass]="nextItemClass()">
      <app-car-small-item
        [item]="this.nextItem"
        *ngIf="this.nextItem"
      ></app-car-small-item>
    </div>
  </div>

  <div>
    <mat-icon>arrow_right</mat-icon>
  </div>
</div>
