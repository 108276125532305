import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-hoh',
  templateUrl: './hoh.component.html',
  styleUrls: ['../app.component.css', './hoh.component.css'],
})
export class HohComponent implements OnInit {
  @Input() parent: AppComponent;
  constructor() {}

  ngOnInit(): void {}
}
