import { Component, OnInit, Input } from '@angular/core';
import { PageContentItem } from 'src/classes/content-classes';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-car-item',
  templateUrl: './car-item.component.html',
  styleUrls: ['./car-item.component.css'],
})
export class CarItemComponent implements OnInit {
  @Input() item: PageContentItem;
  @Input() parent: AppComponent;
  constructor() {}

  ngOnInit(): void {}
}
