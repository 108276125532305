import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-ucon',
  templateUrl: './ucon.component.html',
  styleUrls: ['./ucon.component.css', '../app.component.css'],
})
export class UconComponent implements OnInit {
  @Input() parent: AppComponent;
  constructor() {}

  ngOnInit(): void {}
}
