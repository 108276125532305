import { Component, OnInit, Input } from '@angular/core';
import { PageContentItem } from 'src/classes/content-classes';

@Component({
  selector: 'app-car-small-item',
  templateUrl: './car-small-item.component.html',
  styleUrls: ['./car-small-item.component.css'],
})
export class CarSmallItemComponent implements OnInit {
  @Input() item: PageContentItem;
  constructor() {}

  ngOnInit(): void {}
}
