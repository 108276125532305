import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from '../app.component';
import { PageContentItem } from 'src/classes/content-classes';

@Component({
  selector: 'app-newc-car-component-small',
  templateUrl: './newc-car-component-small.component.html',
  styleUrls: ['./newc-car-component-small.component.css'],
})
export class NewcCarComponentSmallComponent implements OnInit {
  @Input() parent: AppComponent;
  subCh: any;
  curItem: PageContentItem;
  prevItem: PageContentItem;
  nextItem: PageContentItem;
  moveClass = '';

  constructor() {}

  ngOnInit(): void {
    this.dataInit();
  }

  dataInit() {
    if (this.parent.curCarContent && this.parent.curCarContent.title) {
      // console.warn('renot');
      this.curItem = this.parent.curCarContent;
      this.changeDetectInit();
    } else {
      setTimeout(() => {
        //  console.warn('renat');
        this.dataInit();
      }, 10);
    }
  }

  private changeDetectInit() {
    this.subCh = this.parent.curCarContentChanged.subscribe((forward) => {
      if (forward === true) {
        this.prevItem = this.curItem;
        this.curItem = this.parent.curCarContent;
        this.resetMoveClass(forward);
      } else {
        this.nextItem = this.curItem;
        this.curItem = this.parent.curCarContent;
      }
    });
  }

  resetMoveClass(forward) {
    this.moveClass = 'still';
    if (forward === true) {
      this.nextItem = undefined;
      setTimeout(() => {
        this.moveClass = 'forward';
      }, 0);
    } else {
      this.prevItem = undefined;
      setTimeout(() => {
        this.moveClass = 'backward';
      }, 0);
    }
  }

  prevItemClass() {
    return ['prev-item-cls', this.moveClass];
  }

  curItemClass() {
    return ['cur-item-cls', this.moveClass];
  }

  nextItemClass() {
    return ['next-item-cls', this.moveClass];
  }
}
