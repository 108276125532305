import { Component, OnInit, Input } from '@angular/core';
import { PageContentItem } from 'src/classes/content-classes';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css', '../app.component.css'],
})
export class PrivacyComponent implements OnInit {
  @Input() parent: AppComponent;
  pageContent: PageContentItem[];
  constructor() {}

  ngOnInit(): void {
    if (this.parent.canInit !== true) {
      setTimeout(() => {
        this.ngOnInit();
      }, 0);

      return;
    }
    this.pageContent = this.parent.resources.content.privacy;
    /*
    const p2 = new PageContentItem();
    
    p2.title = '情報セキュリティ方針';
    p2.description = '1 趣旨';
    p2.image = 'resources/gfx/ex/private.jpg';
    p2.bigImg = true;
    p2.fullText = `ITを利用した経営環境が、国内の企業に導入されて久しい。その間、当社のビジネスにおいて様々な情報を、業務上で扱うことが当然のこととなった。
ITを利用したビジネスを展開している当社にとって、「セキュリティの確保」は必須事項である。昨今の度重なるセキュリティ事件は、当社にとっても「対岸の火事」ではなく、問題を発生させないために、早急に対応しなければならない経営課題である。
お客様との関係において、セキュリティ事件が発生した場合の営業機会の損失は甚だしいものになることは想像に難くない。当社の今後のビジネス展開を確固たるものにするためにも「セキュア」なブランドイメージを早急に構築しなければならない。
そのために、当社は、情報やコンピュータ及びネットワーク等の情報システム(以下、情報資産)を扱う企業として当社内及びお客様の情報資産を重要な資産とし、保護・管理しなければならない。
当社は、情報資産を保護する「情報セキュリティマネジメント」を実施するために、『情報セキュリティポリシー』を策定する。 『情報セキュリティポリシー』は、当社の情報資産を、故意や偶然という区別に関係なく、改ざん、破壊、漏洩等から保護されるような管理策をまとめた文書である。
当社及びお客様の情報資産にアクセスする者は、情報セキュリティの重要性を十分に認知し、この『情報セキュリティポリシー』を遵守しなければならない。`;
    this.pageContent.push(p2);*/
  }
}
