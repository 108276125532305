import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from '../app.component';
import { PageContentItem } from 'src/classes/content-classes';

@Component({
  selector: 'app-newc-car-component',
  templateUrl: './newc-car-component.component.html',
  styleUrls: ['./newc-car-component.component.css'],
})
export class NewcCarComponentComponent implements OnInit {
  @Input() parent: AppComponent;

  subCh: any;
  curItem: PageContentItem;
  prevItem: PageContentItem;
  nextItem: PageContentItem;
  moveClass = '';

  constructor() {}

  ngOnInit(): void {
    this.dataInit();
  }

  dataInit() {
    if (this.parent.curCarContent && this.parent.curCarContent.title) {
      this.curItem = this.parent.curCarContent;
      this.changeDetectInit();
    } else {
      setTimeout(() => {
        this.dataInit();
      }, 1000);
    }
  }

  private changeDetectInit() {
    this.subCh = this.parent.curCarContentChanged.subscribe((forward) => {
      if (forward === true) {
        this.prevItem = this.curItem;
        this.curItem = this.parent.curCarContent;
      } else {
        this.nextItem = this.curItem;
        this.curItem = this.parent.curCarContent;
      }
      this.resetMoveClass(forward);
    });
  }

  resetMoveClass(forward) {
    this.moveClass = 'still';
    if (forward === true) {
      this.nextItem = undefined;
      setTimeout(() => {
        this.moveClass = 'forward';
      }, 0);
    } else {
      this.prevItem = undefined;
      setTimeout(() => {
        this.moveClass = 'backward';
      }, 0);
    }
  }

  prevItemClass() {
    return ['prev-item-cls', this.moveClass];
  }

  curItemClass() {
    return ['cur-item-cls', this.moveClass];
  }

  nextItemClass() {
    return ['next-item-cls', this.moveClass];
  }

  gForwardNew() {
    if (this.parent.carBtnSrollDisabled === true) {
      return;
    }
    this.parent.nextCar();
    this.parent.carBtnSrollDisabled = true;
    setTimeout(() => {
      this.parent.carBtnSrollDisabled = false;
    }, 1300);
  }
  gBackNew() {
    if (this.parent.carBtnSrollDisabled === true) {
      return;
    }
    this.parent.prevCar();
    this.parent.carBtnSrollDisabled = true;
    setTimeout(() => {
      this.parent.carBtnSrollDisabled = false;
    }, 1300);
  }
}
