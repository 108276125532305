<div class="news-item">
  <div class="img-btn-wrap" *ngIf="item.image">
    <div
      class="page-content-img"
      [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }"
    ></div>
  </div>
  <div class="page-content-txt-wrap">
    <div class="page-content-txt-title">
      {{ this.item.title }}
    </div>
    <div class="page-content-txt-desc">
      {{ this.item.description }}
    </div>

    <div class="page-content-text">
      {{ this.item.fullText }}
    </div>
    <div
      style="
        position: absolute;

        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        bottom: 10px;
      "
    >
      <button
        mat-button
        class="page-content-btn"
        (click)="parent.contentNavigate(item.linkUrl, 'news')"
      >
        {{ item.linkText ? item.linkText : "詳しくは「ニュース」ページへ" }}
      </button>
    </div>
  </div>
</div>
