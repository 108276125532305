import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { HttpClientModule } from '@angular/common/http';
import { MobileContentComponent } from './mobile-content/mobile-content.component';
import { DesktopContentComponent } from './desktop-content/desktop-content.component';

import { NewcCarComponentComponent } from './newc-car-component/newc-car-component.component';
import { NewcCarComponentSmallComponent } from './newc-car-component-small/newc-car-component-small.component';
import { CarSmallItemComponent } from './car-small-item/car-small-item.component';
import { CarItemComponent } from './car-item/car-item.component';
import { MainComponent } from './main/main.component';
import { CompanyComponent } from './company/company.component';
import { HohComponent } from './hoh/hoh.component';
import { UconComponent } from './ucon/ucon.component';
import { ServicesComponent } from './services/services.component';
import { RecruitComponent } from './recruit/recruit.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { NewsComponent } from './news/news.component';

@NgModule({
  declarations: [
    AppComponent,
    MobileContentComponent,
    DesktopContentComponent,
    NewcCarComponentComponent,
    NewcCarComponentSmallComponent,
    CarSmallItemComponent,
    CarItemComponent,
    MainComponent,
    CompanyComponent,
    HohComponent,
    UconComponent,
    ServicesComponent,
    RecruitComponent,
    ContactComponent,
    PrivacyComponent,
    NewsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
