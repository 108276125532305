<div class="top-pan"></div>
<div class="top-pan-text-wrapper">
  <div [ngStyle]="parent.TopPanTextStyle()" class="top-pan-text">
    {{ this.parent.resources?.system?.backtexts?.main1 }}
  </div>
</div>
<div class="content-wrapper">
  <div class="scroll-area" [ngClass]="parent.scrollAreaClass()">
    <ng-container *ngIf="this.parent.pageContent">
      <div
        class="page-content"
        *ngFor="let pc of this.parent.pageContent; index as ix"
        id="{{ 'content1' + ix }}"
        [ngClass]="parent.pageContentVisibilityClass(1, ix, pc)"
      >
        <ng-container *ngIf="this.parent.innerWidth > 682">
          <app-desktop-content
            [parent]="parent"
            [pc]="pc"
          ></app-desktop-content>
        </ng-container>
        <app-mobile-content
          *ngIf="this.parent.innerWidth <= 682"
          [parent]="parent"
          [pc]="pc"
        >
        </app-mobile-content>
      </div>
    </ng-container>
    <div style="text-align: center; color: var(--sub-color)"></div>
  </div>
</div>

<div class="top-pan-2" [ngClass]="parent.TopPan2CoolStyle()">
  <div [ngStyle]="parent.TopPanTextStyle()" class="top-pan-text">
    {{ this.parent.resources?.system?.backtexts?.main2 }}
  </div>
</div>

<div class="content-wrapper">
  <div class="scroll-area" [ngClass]="parent.scrollAreaClass()">
    <ng-container *ngIf="this.parent.pageContent">
      <div
        class="page-content"
        *ngFor="let pc of this.parent.pageContent2; index as ix"
        id="{{ 'content2' + ix }}"
        [ngClass]="parent.pageContentVisibilityClass(2, ix, pc)"
      >
        <ng-container *ngIf="this.parent.innerWidth > 682">
          <app-desktop-content
            [parent]="parent"
            [pc]="pc"
          ></app-desktop-content>
        </ng-container>
        <app-mobile-content
          *ngIf="this.parent.innerWidth <= 682"
          [parent]="parent"
          [pc]="pc"
        >
        </app-mobile-content>
      </div>
    </ng-container>
    <div style="text-align: center; color: var(--sub-color)"></div>
  </div>
</div>

<div class="top-pan-3-pic">
  <div [ngStyle]="parent.TopPanTextStyle()" class="top-pan-text">
    {{ this.parent.resources?.system?.backtexts?.main3 }}
  </div>
</div>
<div class="top-pan-3"></div>
