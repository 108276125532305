import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from '../app.component';
import { PageContentItem } from 'src/classes/content-classes';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css', '../app.component.css'],
})
export class ContactComponent implements OnInit {
  @Input() parent: AppComponent;
  pageContent: PageContentItem[];
  constructor() {}

  ngOnInit(): void {
    if (this.parent.canInit !== true) {
      setTimeout(() => {
        this.ngOnInit();
      }, 0);

      return;
    }
    this.pageContent = this.parent.resources.content.contact;
    /*
    const p2 = new PageContentItem();
   
    p2.title = 'お問い合わせ';
    p2.description = 'C o n t a c t';
    p2.image = 'resources/gfx/ex/access.jpg';

    p2.gridView = [
      { title: 'メールアドレス', text: 'hns@h-ns.jp' },
      {
        title: '住所',
        text: `〒541-0054
大阪市中央区南本町4丁目3-16-1002`,
      },
    ];
    this.pageContent.push(p2);*/
  }
}
