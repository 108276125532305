import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from '../app.component';
import { PageContentItem } from 'src/classes/content-classes';

@Component({
  selector: 'app-mobile-content',
  templateUrl: './mobile-content.component.html',
  styleUrls: ['./mobile-content.component.css'],
})
export class MobileContentComponent implements OnInit {
  @Input() parent: AppComponent;
  @Input() pc: PageContentItem;
  constructor() {}

  ngOnInit(): void {}
}
