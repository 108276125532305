import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from '../app.component';
import { PageContentItem } from 'src/classes/content-classes';
import { title } from 'process';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css', '../app.component.css'],
})
export class CompanyComponent implements OnInit {
  @Input() parent: AppComponent;
  pageContent: PageContentItem[];
  constructor() {}

  ngOnInit(): void {
    if (this.parent.canInit !== true) {
      setTimeout(() => {
        this.ngOnInit();
      }, 0);

      return;
    }
    this.pageContent = this.parent.resources.content.company;

    // const p1 = new PageContentItem();
    // p1.image = 'resources/gfx/ex/index_com.jpg';
    //p1.title = '企業理念';
    //p1.description = 'より良い社会構築を目指して。';
    /*p1.fullText = `我々は常日頃からビジネスその物が少しでも社会貢献に繋がる様にと意識しながら企業活動を進めています。
それは継続性のあるビジネスと社会貢献活動がリンクすることで、より良い社会を構築できると信じているからです。
決して身構えた社会貢献活動でなく、ビジネスの一部が社会貢献に繋がる様に常に意識することからビジネスをスタートさせる企業であり、 そして社会に貢献できる企業人であることを意識して継続的な社会貢献活動を実践することがＨＮＳ社のミッションです。'`;*/

    //this.pageContent.push(p1);

    // const p2 = new PageContentItem();
    /*p2.title = '企業理念';*/
    // p2.description = '会社概要';
    // p2.linkText = '労働者派遣事業の状況に関する情報';
    /*p2.linkUrl = {
      name: 'link',
      urls: ['resources/労働者派遣事業の状況に関する情報.pdf'],
    };*/
    /*
    p2.gridView = [
      { title: '会社名', text: '株式会社HNS' },
      { title: '設立', text: '平成23年11月4日' },
      {
        title: '事業内容',
        text: `金融・流通関連の業務系システム開発
オープン関連の業務系システム開発
インフラ保守・管理業務系システム開発
ホームページ＆Web系システム開発
若手技術者育成支援事業
シニア技術者独立支援事業
ソーシャルビジネスコンサルティング事業`,
      },
      { title: '住所', text: '〒541-0054 大阪市中央区南本町四丁目3-16-1002' },
      { title: 'メールアドレス', text: 'hns@h-ns.jp' },
    ];
    this.pageContent.push(p2);*/
    /*
    const p3 = new PageContentItem();
    p3.image = 'resources/gfx/ex/kaisya.jpg';
    p3.bigImg = true;
    this.pageContent.push(p3);*/
  }
}
