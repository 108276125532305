import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from '../app.component';
import { PageContentItem } from 'src/classes/content-classes';

@Component({
  selector: 'app-recruit',
  templateUrl: './recruit.component.html',
  styleUrls: ['./recruit.component.css', '../app.component.css'],
})
export class RecruitComponent implements OnInit {
  @Input() parent: AppComponent;
  pageContent: PageContentItem[];
  constructor() {}

  ngOnInit(): void {
    if (this.parent.canInit !== true) {
      setTimeout(() => {
        this.ngOnInit();
      }, 0);

      return;
    }
    this.pageContent = this.parent.resources.content.recruit;

    //const p1 = new PageContentItem();
    /*
    p1.image = 'resources/gfx/ex/career.jpg';

    p1.title = '採用情報';
    p1.addClass = 'smally';
    p1.fullText = `契約先企業の技術パートナーとして社会インフラ・金融・物流などの様々な分野で、情報システムに関する幅広い提案・設計を行います。
HNS社では、ご一緒にお仕事をしていただけるＩＴ技術者様・パートナー様を募集させて頂いております。
皆様とのご縁を大切に、日々新しい出逢いを心待ちにしています。`;

    this.pageContent.push(p1);*/

    // const p2 = new PageContentItem();
    /*  p2.addClass = 'smally';
    p2.description = '募集要項';
    p2.gridView = [
      {
        title: '採用職種',
        text: `システムエンジニア
プログラマー
データベースエンジニア　　　等
※経験者優遇・未経験者歓迎`,
      },
      {
        title: '業務内容',
        text: `制御系・組み込み系・通信系
ビジネスアプリケーション　　　等`,
      },
      {
        title: '提出書類',
        text: `履歴書・職務経歴書`,
      },
      {
        title: '必要な経験等',
        text: `実務経験無し可
基礎知識要`,
      },
      {
        title: '連絡先',
        text: `住所：〒541-0054　大阪市中央区南本町4丁目3-16-1002
採用担当：山路（やまじ）
連絡先：yamaji@h-ns.jp`,
      },
    ];

    this.pageContent.push(p2);*/
  }
}
