<div class="car-wrap">
  <div class="back-arrow" (click)="gBackNew()">
    <mat-icon>arrow_left</mat-icon>
  </div>
  <div class="items-wrap">
    <div [ngClass]="prevItemClass()">
      <app-car-item
        [item]="this.prevItem"
        [parent]="this.parent"
        *ngIf="this.prevItem"
      ></app-car-item>
    </div>
    <div [ngClass]="curItemClass()">
      <app-car-item
        [item]="this.curItem"
        [parent]="this.parent"
        *ngIf="this.curItem"
      ></app-car-item>
    </div>
    <div [ngClass]="nextItemClass()">
      <app-car-item
        [item]="this.nextItem"
        [parent]="this.parent"
        *ngIf="this.nextItem"
      ></app-car-item>
    </div>
  </div>

  <div class="back-arrow" (click)="gForwardNew()">
    <mat-icon>arrow_right</mat-icon>
  </div>
</div>
