<div class="news-item">
  <div
    *ngIf="item.image"
    class="page-content-img"
    [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }"
  ></div>
  <div class="page-content-txt-wrap">
    <div class="page-content-txt-title">
      {{ this.item.title }}
    </div>
    <div class="page-content-txt-desc">
      {{ this.item.description }}
    </div>
  </div>
</div>
