import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ViewChild,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  PageContentItem,
  NewsContentItem,
  HNSroute,
  HNSresources,
  BottomMenuItem,
} from 'src/classes/content-classes';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'HNS';
  dummyCollection = [];
  scrollTop: number;
  innerWidth: number;
  blEnd: number;
  centerEnd: number;
  centerWidth: number;
  cookiesAccepted = false;
  pageContent: Array<PageContentItem>;
  carContent: Array<PageContentItem>;
  curCarContent: PageContentItem;
  curCarContentChanged = new EventEmitter();
  newsContent: Array<PageContentItem>;
  rMargin: string;
  mainTextSize: string;
  SysBtnSize: any;
  SysBtnSizeClass: string;
  conPos = {
    start: '0px',
    end: '0px',
  };
  bottomMenu: Array<BottomMenuItem>;
  @ViewChild('firstBtn') firstBtn: ElementRef<HTMLDivElement>;
  @ViewChild('lastBtn') lastBtn: ElementRef<HTMLDivElement>;
  sizesUpdatedOnce: boolean;
  noAutoCar: boolean;
  carBtnSrollDisabled: boolean;
  pathChanged = new EventEmitter<HNSroute>();
  locationUrl: string;

  routes = [
    {
      name: 'main',
      urls: ['main', ''],
    },
    {
      name: 'company',
      urls: ['company'],
    },
    {
      name: 'link',
      urls: [''],
    },
    {
      name: 'faq',
      urls: ['ucon'],
    },
    {
      name: 'privacy',
      urls: ['privacy'],
    },
    {
      name: 'news',
      urls: ['news'],
    },
    {
      name: 'services',
      urls: ['services'],
    },
    {
      name: 'recruit',
      urls: ['recruit'],
    },
    {
      name: 'contact',
      urls: ['contact'],
    },
  ];
  pageContent2: PageContentItem[];
  resources: HNSresources;
  canInit: boolean;
  isNavigated: boolean;
  constructor(
    public http: HttpClient,
    private loc: Location,
    private route: ActivatedRoute,
    private router: Router
  ) {
    console.warn('(C)株式会社HNS');
    this.http.get('resources/text_ja.json').subscribe((res: HNSresources) => {
      this.resources = res;
      this.canInit = true;
    });
  }

  @HostListener('document:scroll', ['$event']) onScroll($event: Event): void {
    this.updateSizes();
  }

  @HostListener('window:resize', ['$event']) onResize($event: Event): void {
    this.updateSizes();
  }

  genDemocol() {
    this.pageContent = this.resources.content.main1;

    this.pageContent2 = this.resources.content.main2;

    this.bottomMenu = this.resources.bottomMenu;
  }
  BotMenJump(link: string, scroll: boolean) {
    this.pathChanged.emit({ name: link });
    setTimeout(() => {
      if (scroll === true) {
        window.scrollTo({ top: window.innerHeight - 90 });
      }
    }, 0);
  }
  pageContentLineStyle() {
    return { 'margin-right': this.rMargin, 'margin-left': this.rMargin };
  }

  TopPanTextStyle() {
    return {
      'margin-right': this.rMargin,
      'margin-left': this.rMargin,
    };
  }

  hepClick() {
    window.open(
      'https://www.meti.go.jp/press/2020/03/20210304005/20210304005.html',
      'blank'
    );
  }
  ContentInvisibleRandom(curcl: string) {
    if (
      !curcl.includes('content-invisible-l') &&
      !curcl.includes('content-invisible-r')
    ) {
      return Math.random() * 10 > 5
        ? 'content-invisible-l'
        : 'content-invisible-r';
    } else {
      if (curcl.includes('content-invisible-l')) {
        return 'content-invisible-l';
      } else {
        return 'content-invisible-r';
      }
    }
  }

  nextAutoCar() {
    if (this.noAutoCar !== true) {
      const index = this.carContent.indexOf(this.curCarContent);
      if (index > -1) {
        let nIndex = index + 1;
        if (nIndex === this.carContent.length) {
          nIndex = 0;
        }
        this.curCarContent = this.carContent[nIndex];
        this.curCarContentChanged.emit(true);
      } else {
        this.curCarContent = this.carContent[0];
        this.curCarContentChanged.emit(true);
      }
    } else {
    }

    setTimeout(() => {
      this.nextAutoCar();
    }, 5000);
  }

  nextCar() {
    const index = this.carContent.indexOf(this.curCarContent);
    if (index > -1) {
      let nIndex = index + 1;
      if (nIndex === this.carContent.length) {
        nIndex = 0;
      }
      this.curCarContent = this.carContent[nIndex];
      this.curCarContentChanged.emit(true);
    }
  }
  whereCar() {
    if (this.scrollTop > window.innerHeight / 2) {
      return 'car-ius-end';
    } else {
      return 'car-ius-start';
    }
  }

  prevCar() {
    const index = this.carContent.indexOf(this.curCarContent);
    if (index > -1) {
      let nIndex = index - 1;
      if (nIndex === -1) {
        nIndex = this.carContent.length - 1;
      }
      this.curCarContent = this.carContent[nIndex];
      this.curCarContentChanged.emit(false);
    }
  }

  moEn() {
    this.noAutoCar = true;
    /*console.warn('x');*/
  }
  moUt() {
    this.noAutoCar = false;
    /*console.warn('xz');*/
  }

  upperLineClass() {
    /*if (this.innerWidth > 682) {*/
    if (this.scrollTop > window.innerHeight * 0.2) {
      return 'upper-line-up';
    }
    /*}*/
  }

  BottomBarClass() {
    if (
      this.isNavigated === true &&
      this.scrollTop + 200 + window.innerHeight >
        document.scrollingElement.scrollHeight
    ) {
      return 'visible-bottom';
    }
  }
  cSccVal() {
    return 'S@: ' + document.getElementById('acars').style.height;
  }
  TopPan2CoolStyle() {
    let anyVid = false;
    for (let index = 0; index < 3; index++) {
      const mbNull = this.pageContentVisibilityClass(2, index);
      anyVid = anyVid || (mbNull && mbNull[0] === 'content-visible');
    }
    if (anyVid === true) {
      return 'top2-has-vis';
    } else {
      return 'top2-no-vis';
    }
  }
  pageContentVisibilityClass(
    block: number,
    index: number,
    dataitem?: PageContentItem
  ) {
    let rVal = '';
    const item = document.getElementById(
      'content' + block.toString() + index.toString()
    );

    if (this.sizesUpdatedOnce !== true) {
      return this.ContentInvisibleRandom(item ? item.className : '');
    }

    if (!item) {
      return this.ContentInvisibleRandom('');
    }
    const bcr = item.getBoundingClientRect();
    rVal =
      (bcr.bottom > 0 && bcr.bottom < window.innerHeight) ||
      (bcr.top > 0 && bcr.top < window.innerHeight) ||
      (bcr.top < 0 && bcr.bottom > 0)
        ? 'content-visible'
        : this.ContentInvisibleRandom(item.className);
    if (dataitem && dataitem.addClass) {
      return [rVal, dataitem.addClass];
    } else {
      return [rVal];
    }
  }

  genDemoNews() {
    this.newsContent = this.resources.content.news;
    // const p1 = new PageContentItem();
    // const p2 = new PageContentItem();
    // const p3 = new PageContentItem();

    // p1.title = '行動計画の策定について';
    // p2.title = 'サイトリニューアル';
    // p3.title = '事務所移転のお知らせ';
    // p1.bigImg = true;
    // p2.bigImg = true;
    // p3.bigImg = true;

    // p1.description = '2020年3月1日';
    // p2.description = '2018年2月1日';
    // p3.description = '2017年12月1日';

    // p1.fullText = '働き方改革を考慮してHNSとして行動計画の策定を行いました。';
    // p2.fullText = '今後ともどうぞ宜しくお願いいたします。';
    /* p3.fullText = `売上計画の目標達成に向けて、営業職の増員と共に、
営業活動の効率化を目指して事務所移転を行いました。
新生HNSにご期待頂き、より一層のご支援を賜りますようお願い致します。`;
*/
    this.newsContent.push(
      ...[
        //p1,
        // p2,
        //       p3
      ]
    );

    this.carContent = JSON.parse(JSON.stringify(this.newsContent));

    this.nextAutoCar();
  }

  newsWrapperStyle() {
    if (this.innerWidth > 682) {
      return {
        height:
          (
            window.innerHeight -
            263 -
            30 +
            (this.scrollTop < 50 ? this.scrollTop - 50 : 0)
          ).toString() + 'px',
      };
    }
  }

  ngAfterViewInit() {
    this.router.setUpLocationChangeListener();
    this.updateSizes();
    setTimeout(() => {
      this.COntentInit();
    }, 0);
  }
  private COntentInit() {
    if (this.canInit !== true) {
      setTimeout(() => {
        this.COntentInit();
      }, 0);

      return;
    }
    this.genDemocol();
    this.genDemoNews();
    this.pathChanged.subscribe((url: HNSroute) => {
      this.isNavigated = false;
      /*  const strUrl = url.split('#/').pop();*/
      let target = 'hoh';
      const routeObject = this.routes.find((x) => x.name === url.name);
      if (routeObject) {
        if (routeObject.name === 'link') {
          window.open(url.urls[0], 'blank');
          return;
        } else {
          /*  this.loc.go('#/' + routeObject.urls[0]);*/
          target = routeObject.urls[0];
        }
      }
      if (url.replace === true) {
        this.loc.replaceState('#/' + url.name);
      } else {
        this.loc.go('#/' + url.name);
      }
      this.locationUrl = target;
      // this.router.navigateByUrl(target);
      document.scrollingElement.scrollTo({
        top: 0,
      });
      setTimeout(() => {
        this.isNavigated = true;
      }, 0);
      // console.warn(url, this.locationUrl, routeObject, target);
    });

    window.addEventListener('popstate', (ev) => {
      // console.warn('ev', ev);
      // console.error(location.href);
      const tarr = location.href.split('#/');
      const tname = tarr.length > 1 ? tarr.pop() : 'main';
      this.pathChanged.emit({
        name: tname,
        replace: true,
      });
    });
    this.router.events.subscribe((event) => {
      // console.warn(event);
    });

    this.route.queryParams.subscribe((params) => {
      // console.warn(location.href);
    });
    this.route.url.subscribe((params) => {
      // console.error(location.href);
      const tarr = location.href.split('#/');
      const tname = tarr.length > 1 ? tarr.pop() : 'main';
      this.pathChanged.emit({
        name: tname,
        replace: true,
      });
    });
  }

  contentNavigate(linkUrl: HNSroute, widgetCall?: string) {
    if (linkUrl) {
      this.pathChanged.emit(linkUrl);
    } else if (widgetCall === 'news') {
      this.pathChanged.emit({
        name: 'news',
      });
    }
  }

  accpetAllCookies() {
    this.cookiesAccepted = true;
  }

  getSysMenuStyle() {
    return {
      width: (this.centerWidth - 400).toString() + 'px',
    };
  }
  getMainMotoClass() {
    const rVal = new Array<string>();
    if (this.scrollTop > 50) {
      rVal.push('main-moto-down');
    }
    if (this.scrollTop > 50 && this.innerWidth < 1150) {
      rVal.push('main-moto-down2');
    }
    if (this.innerWidth < 992) {
      rVal.push('main-moto-hide');
    }
    if (this.innerWidth > 1600) {
      rVal.push('main-moto-wide');
    } else if (this.innerWidth > 1282) {
      rVal.push('main-moto-mid-wide');
    }
    return rVal;
  }

  getMainMotoStyle() {
    return {
      width:
        (this.centerWidth - (this.innerWidth > 682 ? 430 : 200)).toString() +
        'px',
    };
  }

  newsAreaStyle() {
    return { width: '20%' };
  }
  newsContentAreaStyle() {
    if (this.innerWidth > 682) {
      const pWidth = this.innerWidth * 0.2 - 4;
      return {
        top:
          (this.scrollTop < 50 ? 260 - this.scrollTop : 210).toString() + 'px',
        width: (pWidth > 250 ? pWidth : 250).toString() + 'px',
      };
    } else {
      return {
        top:
          (this.scrollTop < 25 ? 55 - this.scrollTop / 2 : 30).toString() +
          'px',
      };
    }
  }

  getMenuBtnStyle() {
    if (this.innerWidth > 682) {
      return {
        top: (this.scrollTop < 50 ? 80 - this.scrollTop : 30).toString() + 'px',
      };
    } else {
      return {
        top:
          (this.scrollTop < 25 ? 55 - this.scrollTop / 2 : 30).toString() +
          'px',
      };
    }
  }
  updateSizes() {
    this.scrollTop = document.scrollingElement.scrollTop;
    this.innerWidth = window.innerWidth;
    /*console.warn(this.innerWidth);*/
    const blueLine = document.getElementsByClassName('tp-center')?.item(0);
    if (blueLine) {
      const bcr = blueLine.getBoundingClientRect();
      this.centerEnd = bcr.right;
      this.centerWidth = bcr.width;
    }

    this.rMargin = '0%';
    this.mainTextSize = '25px';
    if (this.innerWidth > 1920) {
      this.rMargin = '15%';
      this.mainTextSize = '40px';
    } else if (this.innerWidth > 1600) {
      this.rMargin = '10%';
      this.mainTextSize = '36px';
    } else if (this.innerWidth > 1300) {
      this.rMargin = '5%';
      this.mainTextSize = '32px';
    } else if (this.innerWidth > 1020) {
      this.mainTextSize = '28px';
      this.rMargin = '3%';
    }
    setTimeout(() => {
      this.conPos.start =
        this.firstBtn.nativeElement.getBoundingClientRect().left.toString() +
        'px';
      this.conPos.end =
        (
          this.lastBtn.nativeElement.getBoundingClientRect().right -
          this.firstBtn.nativeElement.getBoundingClientRect().left -
          30
        ).toString() + 'px';
    }, 0);
    setTimeout(() => {
      this.sizesUpdatedOnce = true;
    }, 0);
  }

  getSubMenuStyle() {
    return {
      left: this.conPos.start,
      width: this.conPos.end,
    };
  }
  SysBtnStyle(arg?) {
    if (!arg) {
      return;
    }
    if (arg === this.locationUrl) {
      return 'sys-men-cur-loc';
    }
    /* return { 'font-size': this.SysBtnSize };*/
  }

  scrollAreaClass() {
    return /*this.innerWidth > 1020 ? 'scroll-area-80' :*/ 'scroll-area-100';
  }

  ngOnInit() {
    this.updateSizes();
    for (let index = 0; index < 150; index++) {
      this.dummyCollection.push(index);
    }
    if (this.canInit === true) {
    }
  }

  buttonAction(event) {
    console.warn(event);
    this.http
      .post('Test/GetBaseTable', {
        /*читаемо в [FromBody]*/
      })
      .subscribe((res) => {
        console.warn(res);
      });
  }

  buttonAction2(event) {
    console.warn(event);
    this.http
      .post('Test/WriteNewBaseTable', {
        /*читаемо в [FromBody]*/
      })
      .subscribe((res) => {
        console.warn(res);
      });
  }
}
