<div class="loading-banner lb-after-load">
  <div class="logo"></div>
  人と人との繋がりを大切にし、お客様の満足と、<br />
  社員の幸せを両立する会社を目指します
</div>

<div class="content-area">
  <div class="upper-line" [ngClass]="upperLineClass()">
    <div class="upper-menu">
      <div class="toiawase-btn system-menu-btn logo-top-wrapper">
        <div
          class="logo-top"
          (click)="pathChanged.emit({ name: 'main' })"
        ></div>
      </div>

      <div class="right-menu-buttons right-menu-two-buttons">
        <div
          class="toiawase-btn system-menu-btn div-icon-btn"
          mat-button
          mat-stroked-button
          [ngClass]="SysBtnStyle()"
          *ngIf="false"
        >
          <mat-icon>search</mat-icon>
        </div>

        <div
          class="toiawase-btn system-menu-btn div-icon-btn"
          [ngClass]="SysBtnStyle()"
          mat-button
          mat-stroked-button
          #lastBtn
          [matMenuTriggerFor]="mainMenu"
        >
          <mat-icon>menu</mat-icon>
        </div>
      </div>

      <div class="right-menu-buttons">
        <div
          class="toiawase-btn system-menu-btn first-btn"
          mat-button
          mat-stroked-button
          [ngClass]="SysBtnStyle('services')"
          #firstBtn
          (click)="pathChanged.emit({ name: 'services' })"
        >
          <span class="sys-menu-nihongo">サービス</span>
        </div>

        <div
          class="toiawase-btn system-menu-btn"
          mat-button
          mat-stroked-button
          [ngClass]="SysBtnStyle('company')"
          (click)="pathChanged.emit({ name: 'company' })"
        >
          <span class="sys-menu-nihongo">会社案内</span>
        </div>
        <div
          class="toiawase-btn system-menu-btn"
          mat-button
          mat-stroked-button
          [ngClass]="SysBtnStyle('recruit')"
          (click)="pathChanged.emit({ name: 'recruit' })"
        >
          <span class="sys-menu-nihongo">採用情報</span>
        </div>
        <div
          class="toiawase-btn system-menu-btn"
          mat-button
          mat-stroked-button
          [ngClass]="SysBtnStyle('contact')"
          (click)="pathChanged.emit({ name: 'contact' })"
        >
          <span class="sys-menu-nihongo">お問い合わせ</span>
        </div>
        <div
          class="toiawase-btn system-menu-btn pers-info"
          mat-button
          mat-stroked-button
          [ngClass]="SysBtnStyle('privacy')"
          (click)="pathChanged.emit({ name: 'privacy' })"
        >
          <span class="sys-menu-nihongo">個人情報</span>
          <span class="sys-menu-eigo" *ngIf="innerWidth >= 682">Company</span>
        </div>

        <div
          *ngIf="false"
          class="toiawase-btn system-menu-btn div-icon-btn"
          mat-button
          mat-stroked-button
          [ngClass]="SysBtnStyle()"
        >
          <mat-icon>search</mat-icon>
        </div>

        <div
          class="toiawase-btn system-menu-btn div-icon-btn"
          [ngClass]="SysBtnStyle()"
          mat-button
          mat-stroked-button
          #lastBtn
          [matMenuTriggerFor]="mainMenu"
        >
          <mat-icon>menu</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      this.innerWidth > 480 &&
      (!this.locationUrl || this.locationUrl === 'main')
    "
    class="carius-wrap"
    (mouseover)="moEn()"
    (mouseout)="moUt()"
  >
    <div class="car-ius car-ius-trig" [ngClass]="whereCar()">
      <app-newc-car-component-small
        [parent]="this"
      ></app-newc-car-component-small>
    </div>
    <div class="car-ius car-ius-hove" [ngClass]="whereCar()">
      <app-newc-car-component [parent]="this"></app-newc-car-component>
    </div>
  </div>
  <app-main
    [parent]="this"
    *ngIf="!this.locationUrl || this.locationUrl === 'main'"
  ></app-main>

  <app-company
    [parent]="this"
    *ngIf="this.locationUrl === 'company'"
  ></app-company>

  <app-contact
    [parent]="this"
    *ngIf="this.locationUrl === 'contact'"
  ></app-contact>

  <app-privacy
    [parent]="this"
    *ngIf="this.locationUrl === 'privacy'"
  ></app-privacy>
  <app-news [parent]="this" *ngIf="this.locationUrl === 'news'"></app-news>

  <app-hoh [parent]="this" *ngIf="this.locationUrl === 'hoh'"></app-hoh>

  <app-ucon [parent]="this" *ngIf="this.locationUrl === 'ucon'"></app-ucon>
  <app-services
    [parent]="this"
    *ngIf="this.locationUrl === 'services'"
  ></app-services>
  <app-recruit
    [parent]="this"
    *ngIf="this.locationUrl === 'recruit'"
  ></app-recruit>

  <div class="bottom-bar" [ngClass]="BottomBarClass()">
    <div class="bottom-nav-menu">
      <ng-container *ngFor="let bottomItem of this.bottomMenu">
        <div class="bottom-menu-group">
          <div class="bottom-menu-title" (click)="BotMenJump(bottomItem.link)">
            {{ bottomItem.title }}
          </div>
          <div class="bottom-items-wrapper">
            <div
              *ngFor="let subItem of bottomItem.items"
              class="bottom-menu-item"
              (click)="BotMenJump(bottomItem.link, true)"
            >
              {{ subItem }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="bottom-bar-contents" style="height: 60px">
      <img
        src="resources/gfx/ex/hep.png"
        (click)="hepClick()"
        style="height: 60px; position: absolute"
        class="kenehn-img"
      />
    </div>
    <div class="copy-gdrp">
      <div class="bottom-bar-contents">
        ©&nbsp;株式会社HNS
        <!--<br />〒541-0054 大阪市中央区南本町4丁目3-16-1002-->
      </div>

      <div
        *ngIf="cookiesAccepted === false"
        class="gdrp-confirmation"
        [matMenuTriggerFor]="GDRPMenu"
      >
        サイトは<span class="gdrp-note">Cookie</span>を使用して、<span
          class="gdrp-note"
          >ユーザーデータ</span
        >を収集できます。 詳しくはこちらをクリックしてください。
      </div>
    </div>
  </div>
</div>

<mat-menu #mainMenu="matMenu" [class]="'mai-menu-class'">
  <button mat-menu-item (click)="pathChanged.emit({ name: 'main' })">
    トップページ
  </button>
  <button mat-menu-item (click)="pathChanged.emit({ name: 'news' })">
    更新情報
  </button>
  <button mat-menu-item (click)="pathChanged.emit({ name: 'services' })">
    サービス
  </button>
  <button mat-menu-item (click)="pathChanged.emit({ name: 'company' })">
    会社案内
  </button>

  <button mat-menu-item (click)="pathChanged.emit({ name: 'recruit' })">
    採用情報
  </button>

  <button
    mat-menu-item
    [matMenuTriggerFor]="toiawaseMenu"
    (click)="pathChanged.emit({ name: 'contact' })"
  >
    問い合わせ
  </button>

  <button mat-menu-item (click)="pathChanged.emit({ name: 'privacy' })">
    個人情報
  </button>
</mat-menu>

<mat-menu #toiawaseMenu="matMenu" [class]="'mai-menu-class'">
  <button mat-menu-item (click)="BotMenJump('contact', true)">キャリア</button>
  <button mat-menu-item (click)="BotMenJump('contact', true)">サービス</button>
  <button mat-menu-item (click)="pathChanged.emit({ name: 'faq' })">
    よくある質問
  </button>
</mat-menu>

<mat-menu #GDRPMenu="matMenu" [class]="'coo-menu-class'" xPosition="before">
  <button mat-menu-item (click)="accpetAllCookies()">
    <mat-icon>check</mat-icon>すべて受け入れる
  </button>
  <button mat-menu-item (click)="pathChanged.emit({ name: 'privacy' })">
    <mat-icon>help_outline</mat-icon>もっと詳しく知る
  </button>
</mat-menu>

<!--div class="car-after" #acars id="acars">{{ cSccVal() }}</div-->
