<div class="component-class">
  <div class="top-pan-3-pic">
    <div [ngStyle]="parent.TopPanTextStyle()" class="top-pan-text">
      {{ this.parent.resources?.system?.backtexts?.contact }}
    </div>
  </div>
  <div class="content-wrapper">
    <div class="scroll-area" [ngClass]="parent.scrollAreaClass()">
      <ng-container *ngIf="this.pageContent">
        <div
          class="page-content"
          *ngFor="let pc of this.pageContent; index as ix"
          id="{{ 'content123' + ix }}"
          [ngClass]="parent.pageContentVisibilityClass(123, ix)"
        >
          <ng-container *ngIf="this.parent.innerWidth > 682">
            <app-desktop-content
              [parent]="parent"
              [pc]="pc"
            ></app-desktop-content>
          </ng-container>
          <app-mobile-content
            *ngIf="this.parent.innerWidth <= 682"
            [parent]="parent"
            [pc]="pc"
          >
          </app-mobile-content>
        </div>
        <div class="page-content" style="display: flex">
          <iframe
            width="600"
            height="450"
            style="border: 0; margin: auto"
            loading="lazy"
            allowfullscreen
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJjRi6nx3nAGART0t-izK0u4Q&key=AIzaSyANBHnEfJC8BvL3UM79ZI6mgeijGBFKFnE"
          ></iframe>
        </div>
      </ng-container>
      <div style="text-align: center; color: var(--sub-color)"></div>
    </div>
  </div>
  <div class="top-pan-3"></div>
</div>
