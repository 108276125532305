import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from '../app.component';
import { PageContentItem } from 'src/classes/content-classes';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css', '../app.component.css'],
})
export class ServicesComponent implements OnInit {
  @Input() parent: AppComponent;
  pageContent: PageContentItem[];
  constructor() {}

  ngOnInit(): void {
    if (this.parent.canInit !== true) {
      setTimeout(() => {
        this.ngOnInit();
      }, 0);

      return;
    }
    this.pageContent = this.parent.resources.content.services;

    // const p1 = new PageContentItem();
    // p1.image = 'resources/gfx/ex/service.jpg';
    // p1.bigImg = true;
    // p1.title = 'サービスの特徴';
    // p1.description = 'お客様にとって最適なITソリューションの提案';
    // p1.fullText = `私たちは創業より、ITを通して人と人との繋がりを大切にし、皆様に寄り添えるご提案が出来るよう歩んで参りました。
    // 幅広い年齢層の技術者が集い切磋琢磨出来る環境を提供する事で、様々な分野において技術者としてのスキルアップを目指すことが可能となります。
    // 私たちは将来の夢を実現するノウハウを提供出来る支援会社として、暮らしの様々な場面で社会に貢献して参ります。`;

    // this.pageContent.push(p1);

    // const p2 = new PageContentItem();
    /*  p2.addClass = 'smally';
    p2.description = '技術者のS E S事業';
    p2.fullText = `当社の主要な事業となります。
最適な時に的確な人材で企業をバックアップ出来る様、
数多くの経験を持ったエンジニアの技術をパートナーの皆様へと提供させて頂いております。
時期によって変動する業務量のカバーや
、即戦力による人員の確保など、
是非ご相談ください。`;

    this.pageContent.push(p2);*/
    /*
    const p3 = new PageContentItem();
    p3.addClass = 'smally';
    p3.description = '人材派遣事業';
    p3.fullText = `弊社のミッションである継続的な社会貢献活動の一環としての新しい提供サービスです。
IT業界のみならず、
ヘルプデスクやオペレーション事業といった多方面での活動も行っています。
繋がりある人々のやりたい事、
目指しているもののスキルアップを目指し、
将来の夢を実現するノウハウを提供出来る支援会社として、
寄り添って参ります。`;

    this.pageContent.push(p3);*/
  }
}
