<ng-container>
  <div
    *ngIf="pc.title"
    class="page-content-line page-content-button-and-title"
    [ngStyle]="parent.pageContentLineStyle()"
  >
    <span class="page-content-title">{{ pc.title }}</span>
  </div>
  <div
    *ngIf="pc.image"
    class="page-content-line page-content-img-desc"
    [ngStyle]="parent.pageContentLineStyle()"
  >
    <div
      *ngIf="pc.bigImg != true"
      class="page-content-img"
      [ngStyle]="{ 'background-image': 'url(' + pc.image + ')' }"
    ></div>
    <img
      class="page-content-big-img"
      *ngIf="pc.bigImg === true"
      src="{{ pc.image }}"
    />
  </div>
  <div
    *ngIf="pc.description"
    class="page-content-line page-content-img-desc"
    [ngStyle]="parent.pageContentLineStyle()"
  >
    <span class="page-content-desc">{{ pc.description }}</span>
  </div>
  <div
    class="page-content-line page-content-full-text"
    [ngStyle]="parent.pageContentLineStyle()"
    *ngIf="pc.fullText"
  >
    {{ pc.fullText }}
  </div>
  <div class="page-content-fulltext" *ngIf="pc.gridView">
    <table>
      <ng-container *ngFor="let gridLine of pc.gridView">
        <tr class="contents-grid-line">
          <td class="grid-line-title">
            {{ gridLine.title }}
          </td>
        </tr>
        <tr class="contents-grid-line">
          <td
            class="grid-line-text"
            style="white-space: pre-line"
            *ngIf="gridLine.text"
          >
            {{ gridLine.text }}
          </td>
          <td
            class="grid-line-text"
            style="white-space: pre-line"
            *ngIf="gridLine.component"
          >
            <ng-container *ngIf="gridLine.component === 'map'">
              <iframe
                width="320"
                height="450"
                style="border: 0; margin: auto"
                loading="lazy"
                allowfullscreen
                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJjRi6nx3nAGART0t-izK0u4Q&key=AIzaSyANBHnEfJC8BvL3UM79ZI6mgeijGBFKFnE"
              ></iframe>
            </ng-container>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>

  <div
    class="page-content-line page-content-button-and-title"
    [ngStyle]="parent.pageContentLineStyle()"
    *ngIf="pc.linkText"
  >
    <button
      mat-button
      class="page-content-button"
      mat-stroked-button
      (click)="parent.contentNavigate(pc.linkUrl)"
    >
      {{ pc.linkText }}
    </button>
  </div>

  <div
    *ngIf="pc.linkArray"
    class="page-content-line page-content-button-and-title"
    [ngStyle]="parent.pageContentLineStyle()"
    style="display: block"
  >
    <div *ngFor="let link of pc.linkArray" style="padding-bottom: 5px">
      <button
        mat-button
        class="page-content-button"
        mat-stroked-button
        (click)="parent.contentNavigate(link.linkUrl)"
      >
        {{ link.linkText }}
      </button>
    </div>
  </div>
</ng-container>
