import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from '../app.component';
import { PageContentItem } from 'src/classes/content-classes';

@Component({
  selector: 'app-desktop-content',
  templateUrl: './desktop-content.component.html',
  styleUrls: ['./desktop-content.component.css', '../app.component.css'],
})
export class DesktopContentComponent implements OnInit {
  @Input() parent: AppComponent;
  @Input() pc: PageContentItem;
  constructor() {}

  ngOnInit(): void {}
}
